<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4'>
   
      <b-col v-if='items'>
        <b-form @submit='onSubmit'>

          <router-link class="btn btn-sm btn-dark  "  size="sm"  :to="{ path: '/brands'}">
            <em class="fa fa-backward btn-sm btn-dark "></em> Back
          </router-link>
          <div class='card'>
            <div class='bg-primary p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Edit Existing Brands ({{form.name}})</h5>
            </div>
            
            
            <div class='card-body'>
              <b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
								</b-col>
							</b-row>
              <b-row>
                <b-col lg='4'>
                    <b-form-group label='Name *'>
                      <b-form-input id='name' v-model='form.name'  step='any'
                        required>
                      </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Brand Code *'>
                    <b-form-input id='name' v-model='form.brand_code'  step='any'
                      required>
                    </b-form-input>
                  </b-form-group>
              </b-col>
              
              <b-col lg='4'>
                <label>Publisher *</label>
                <multiselect v-model='form.vendor_id' label='name' track-by='name'
                  placeholder='Enter Publisher' open-direction='bottom' :options='vendorSearchOptions'
                  :searchable='true' :loading='isVendorSearch' :close-on-select='true' 
                  @search-change='getVendorFind'>
                </multiselect>
              </b-col>
              </b-row>

              <b-row>
                <b-col lg='4'>
                  <b-form-group label='Default Items Type *'>
                    <b-form-select id="defaultItemsType" v-model="form.default_item_type" :options="defaultItemTypeOption" required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Default Items Scedule *'>
                    <b-form-select id="defaultitemschedule" v-model="form.default_item_schedule" :options="defaultBrandTypeOption" required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                <b-form-group label='Release Period *'>
                  <b-form-select id="releasePeriod" v-model="form.release_period" :options="defaultReleasePeriodOption" required>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              
                <div class="col-md-4">
                <label>Default Languages *</label>
                <multiselect
                  placeholder="Enter languages"
                  open-direction="bottom"
                  v-model="form.default_languages"
                  label="name"
                  track-by="name"
                  :custom-label="nameWithISO"
                  :options="languagesSearchOptions"
                  :searchable="true"
                  :close-on-select="true"
                  :options-limit="10"
                  :multiple="true"
                  
                  required
                >
                </multiselect>
              </div>
              <div class="col-md-4">
                <label>Default Countries *</label>
                <multiselect
                  placeholder="Enter Countries"
                  open-direction="bottom"
                  v-model="form.default_countries"
                  label="iso"
                  track-by="iso"
                  :custom-label="nameWithISO"
                  :options="countriesSearchOptions"
                  :loading="isCountriesSearch"
                  :searchable="true"
                  :close-on-select="true"
                  :options-limit="10"
                  :multiple="true">
                </multiselect>
              </div>
            </b-row>
            
            <b-row>
              <div class="col-md-12">
                <b-form-group label='Description'>
                  <b-form-textarea id="description" v-model="form.description" placeholder="Enter Description Here..." rows="3" max-rows="6">
                  </b-form-textarea>
                </b-form-group>
              </div>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-checkbox v-model='form.is_active' :value=true unchecked-value='false'> Is Active
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            
            <b-row>
              <label></label>
              <div class="col-md-4">
                  <b-button variant='primary' type='submit'>Update</b-button>
              </div>
            </b-row>

            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { EditBrandPath } from '../../router/clients';
import constant from "../../store/constant";

export default {
  name: 'editBrands',
  data() {
    return {
      EditBrandPath,
      debounce: null,
      id: null,
      form: {
        id:null,
        name:null,
        brand_code:null,
        default_item_type:null,
        default_item_schedule:null,
        vendor_id:null,
        release_period:null,
        description:null,
        default_countries:[],
        default_languages:[],
        sort_priority:null,
        is_active: false,
      },
      isCountriesSearch: false,
      isVendorSearch:false,
      defaultItemTypeOption: [
				{ value: constant.ITEM_TYPES.MAGAZINE.toLowerCase(), text: "Magazine" },
        { value: constant.ITEM_TYPES.MAGAZINE.toLowerCase(), text:"Magazine"},
        { value: constant.ITEM_TYPES.BOOK.toLowerCase(), text: "Book" },
        { value: constant.ITEM_TYPES.NEWSPAPER.toLowerCase(), text: "Newspaper" },
        { value: constant.ITEM_TYPES.AUDIOBOOK.toLowerCase(), text: "Audiobook" },
      ],
      defaultBrandTypeOption:[
        { value: constant.BRAND_TYPE.EDITION, text: "Edition" },
        { value: constant.BRAND_TYPE.MONTHLY, text: "Monthly" },
      ],
      defaultReleasePeriodOption:[
        { value: constant.BRAND_RELEASE_PERIOD.DAILY, text: "Daily" },
        { value: constant.BRAND_RELEASE_PERIOD.WEEKLY, text: "Weekly" },
        { value: constant.BRAND_RELEASE_PERIOD.BIWEEKLY, text: "Bi-Weekly" },
        { value: constant.BRAND_RELEASE_PERIOD.MONTHLY, text: "Monthly" },
        { value: constant.BRAND_RELEASE_PERIOD.BIMONTHLY, text: "Bi-Monthly" },
        { value: constant.BRAND_RELEASE_PERIOD.QUARTERLY, text: "Quaterly" },
        { value: constant.BRAND_RELEASE_PERIOD.SPECIAL, text: "Special" },
      ],
      countriesSearchOptions: [],
      vendorSearchOptions:[],
      languagesSearchOptions: [],
    };
  },
  computed: {
    ...mapState({
      totalRows: (state) => state.brands.totalRows,
      items :(state) => state.brands.items,
      isLoading : (state) => state.brands.isLoading,
      isSuccess : (state) => state.brands.isSuccess,
      successMessage: (state) => state.brands.successMessage,
      errorMessage:(state) => state.brands.errorMessage,
      refresh: (state) => state.brands.refresh,
    }),
  },
  watch: {
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      this.$router.push({
        path: `/brands`,
      })
    },
    errorMessage: function () {
      if (!this.errorMessage) return;
      return this.baseAlert({
          icon:'error',
          title: 'Error',
          text: this.errorMessage,
        });
    },
    items: function () {
      if(!this.items){
        return this.baseAlert({
          icon:'error',
          title: 'Error',
          text: 'No Items Avaiable',
        });
      }
      this.setEditBrands();    
    }
  },
  created() {
    this.setCountriesOptions();
    this.languagesSearchOptions = Object.entries(constant.LANGUAGE_CHOICES).map((items) => { 
      return {
        iso: items[0].toUpperCase(),
        name: items[0]
      } 
    });
    this.actionGetBrands();
  },
  mounted() {
    this.actionGetBrands();
  },
  methods: {
  ...mapActions("brands", ["searchVendorById","searchVendor","getCountries","fetchBrandsByid","updateBrands"]),
    actionGetBrands() {
      let id=this.$route.params.id;
      this.id = this.$route.params.id;
      let payload = {
        id,
      };
      this.fetchBrandsByid(payload);
    },
  onSubmit(event) {
		event.preventDefault();
		const data = this.form;
    const payload = {
      id: this.id,
      name: data.name,
      brand_code: data.brand_code,
      vendor_id:data.vendor_id.id,
      default_item_type:data.default_item_type,
      default_item_schedule:data.default_item_schedule,
      release_period:data.release_period,
      description:data.description,
      default_languages: data.default_languages.map(item => item.iso.toLowerCase()),
      default_countries: data.default_countries.map(item=>item.iso.toLowerCase()),
      is_active:data.is_active,
    }
    this.updateBrands(payload);
    
	},onReset() {

  },setCountriesOptions() {
      this.isCountriesSearch = true;
      this.getCountries()
      .then((response) => {
        this.countriesSearchOptions = response.data.data.rows;
        this.isCountriesSearch = false;
      })
      .catch(() => {
        this.isCountriesSearch = false;
      });
  },getVendorFind(query){
      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchVendor({ q: query }).then((response) => {
          this.vendorSearchOptions = response.data.data.rows;});
        this.isVendorSearch = false;
      }, 600);
  },messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
  },nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
  },setEditBrands() {
      const data = this.items;
      this.form.name = data.name;
      this.form.brand_code = data.brand_code;
      if(data.vendor_id){
        this.searchVendorById(data.vendor_id).then(response => {
        this.form.vendor_id = response.data?.data?.rows[0];
        this.vendorSearchOptions = response.data?.data?.rows;
      }); 
      }
      this.form.description=data.description;
      this.form.default_item_type = data.default_item_type;
      this.form.default_item_schedule = data.default_item_schedule;
      this.form.release_period= data.release_period;
      if(data.default_languages){
        this.form.default_languages = this.languagesSearchOptions
        .filter(
          items => [...data.default_languages].includes(items.iso.toLowerCase())
      );
      }
      if(data.default_countries){
        this.form.default_countries = this.countriesSearchOptions
        .filter(
          items => [...data.default_countries].includes(items.iso.toLowerCase())
      );
      }
      this.form.description = data.description;
      this.form.is_active=data.is_active;
    }
    ,baseAlert(data) {
        this.$swal({
          icon: data.icon,
          title: data.title,
          text: data.text,
        })
    },
  }
  
};

</script>
